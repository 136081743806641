h1 {
  font-size: calc(36pt + 2vmin);
  font-weight: bold;
}
h2 {
  font-size: calc(24pt + 2vmin);
}
h3 {
  font-size: calc(18pt + 2vmin);
}
h4 {
  font-size: calc(14pt + 2vmin);
}
p {
  font-size: calc(10pt + 2vmin);
}

.App {
  z-index: auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  align-content: center;
  overflow: hidden;
}

.App-header {
  position: fixed;
  top: 0;
  z-index: 200;
  width: 100vw;
}

.App-footer {
  position: relative;
  bottom: 0;
  align-content: center;
  text-align: center;
  background-color: black;
  color: lightgrey;
  font-size: x-small;
  width: 100%;
}

.App-footer p {
  font-size: calc(10pt + 1vmin);
}

.App-copyright {
  margin: auto;
  text-align: justify;
  text-align-last: center;
  max-width: 80vw;
}

.App-footer-song {
  padding: 0;
  margin: auto;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  max-width: 90vw;
}

.App-footer-song-middle {
  padding: 0;
  padding-top: 1rem;
  text-align: justify;
  text-align-last: center;
  max-width: 30rem;
}

.App-footer-song-buffer-left, .App-footer-song-buffer-right {
  padding: 0;
  vertical-align: bottom;
  font-size: 10vmin;
}

.App-footer-song-buffer-left {
  text-align: end;
}

.App-footer-song-buffer-right {
  text-align: start;
}

.navbar {
  height: 3rem;
}

.App-background {
  position: fixed;
  background-image: linear-gradient(
      30deg,
      rgba(0, 183, 236, 1) 40%,
      rgba(255, 255, 255, 0) 40%
    ),
    linear-gradient(20deg, rgba(0, 228, 213, 1) 50%, rgba(255, 255, 255, 0) 50%);
  background-size: 100vw;
  background-repeat: no-repeat;
  background-position-y: bottom;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -10;
  height: 100vh;
  width: 100%;
}

.App-content {
  position: absolute;
  top: 3rem;
  color: rgb(0, 95, 139);
  background: rgba(0, 0, 0, 0);
  overflow-y: auto;
  overflow-x: hidden;
  opacity: 0;
}

.App-content-inner {
  padding: 2rem;
}

.App-homepage {
  text-align: center;
}

.App-page {
  text-align: left;
}

.App-link {
  color: #004ba0;
}

img {
  max-width: 100%;
}

/* Note: Collapse watermark when scaled in */
@media all and (max-width: 62rem) {
  .App-gutter{
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
}

@media all and (max-width: 50rem) {
  .App-footer-song-buffer-left, .App-footer-song-buffer-right {
    display: none;
    width: 0;
    height: 0;
    opacity: 0;
    visibility: collapse;
  }
}

.App-gutter {
  position: sticky;
}

.App-watermark:empty::before {
  content: "\00a0";
}

.App-watermark::before {
  background-image: url("./content/logo_withshadow.png");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 20vmax;
  right: 0rem;
  top: 0rem;
  content: "";
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: -100;
  pointer-events: none;
}

.App-watermark:empty::before {
  content: "\00a0";
}
